import React, { ComponentType, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import routes from '../../server/routes';

import { SubUnit } from '../../components/Toolkit/Card/card-components/SubUnit';
import ErrorBoundary from '../../helpers/components/ErrorBoundary';
import {
  BASE_URL,
  CDN_URL_STATIC_DIRECTORY,
  checkCookieExists,
  safeGet,
} from '../../helpers/utils';
import {
  FilterRequestAPI,
  FilterSearchQuery,
} from '../../services/Filters/FilterTypes';
import { Listing, SearchPropertyCard } from '../../types';

import * as S from './SearchPage.styled';
import ZeroResults from './ZeroResults';

const { Router } = routes;

const MapSearchModal: ComponentType<any> = dynamic(
  () =>
    import(
      /*webpackChunkName: "MapSearchModal-CHUNK"*/ '../Toolkit/MapSearch/MapSearchModal'
    ).then((module) => module.MapSearchModal),
  {
    ssr: false,
  },
);

const MapSearch: ComponentType<any> = dynamic(
  () =>
    import(
      /*webpackChunkName: "MapSearch-CHUNK"*/ '../Toolkit/MapSearch/MapSearch'
    ).then((module) => module.MapSearch),
  {
    ssr: false,
  },
);

const MapSearchCarousel: ComponentType<any> = dynamic(
  () =>
    import(
      /*webpackChunkName: "MapSearchCarousel-CHUNK"*/ '../../components/Toolkit/MapSearch/MapSearchCarousel'
    ).then((module) => module.MapSearchCarousel),
  {
    ssr: false,
  },
);

type SearchPageMapViewProps = {
  addHashToUrl: (id: number | string) => void;
  addSessionStorage: (id: number) => void;
  debouncedOnChange: (filterObject: FilterRequestAPI) => void;
  filterAPI: FilterRequestAPI[];
  hiddenFilterAPI: FilterRequestAPI[];
  agentIdsFilter?: FilterSearchQuery;
  isLoading: boolean;
  isCommercial: boolean;
  isMapView: boolean;
  isMobile: boolean;
  listings: SearchPropertyCard[];
  paging: any;
  queryFilters?: FilterSearchQuery[];
  savedAds: {
    [index: number]: boolean;
  };
  selectedListing: Listing | null;
  setSelectedListing: React.Dispatch<React.SetStateAction<Listing | null>>;
  seoH1: string;
  showcaseListings?: [SearchPropertyCard];
  resetFilter: (filter: FilterRequestAPI) => void;
  toggleMapView: () => void;
  toggleSavedAd: ({
    adId,
    event,
    isSaved,
    userId,
  }: {
    adId: number;
    event: React.MouseEvent;
    isSaved?: boolean;
    userId: string;
  }) => void;
};

const SearchPageMapView = (props: SearchPageMapViewProps) => {
  const {
    addHashToUrl,
    addSessionStorage,
    debouncedOnChange,
    isCommercial,
    isLoading,
    filterAPI,
    hiddenFilterAPI,
    agentIdsFilter,
    isMapView,
    isMobile,
    listings,
    paging,
    queryFilters,
    resetFilter,
    savedAds,
    selectedListing,
    seoH1,
    setSelectedListing,
    showcaseListings,
    toggleMapView,
    toggleSavedAd,
  } = props;

  const [onboardingModal, setOnboardingModal] = useState(false);

  const handleOnboardingModal = () => {
    const hasDaftMapCookie = checkCookieExists('daftMap');
    if (isMapView && !hasDaftMapCookie) {
      setOnboardingModal(true);
    }
  };

  useEffect(() => {
    handleOnboardingModal();
  }, []);

  const closeOnboardingModal = () => {
    setOnboardingModal(false);
  };

  return (
    <ErrorBoundary
      renderFallback={() => (
        <S.ErrorMessageContainer>
          Sorry, something went wrong when trying to load map search, please
          refresh the page.
        </S.ErrorMessageContainer>
      )}
    >
      <MapSearchModal modalIsOpen={isMapView} closeModal={toggleMapView}>
        <S.MapWrapper data-testid="mapsearch-wrapper">
          {!isMobile ? (
            <MapSearchCarousel
              addSessionStorage={addSessionStorage}
              BASE_URL={BASE_URL}
              filterAPI={filterAPI}
              hiddenFilterAPI={hiddenFilterAPI}
              IS_COMMERCIAL={isCommercial}
              agentIdsFilter={agentIdsFilter}
              isLoading={isLoading}
              listings={listings}
              queryFilters={queryFilters}
              resetFilter={resetFilter}
              routes={routes}
              savedAds={savedAds}
              seoH1={seoH1}
              setSelectedListing={setSelectedListing}
              showcaseListings={showcaseListings}
              toggleSavedAd={toggleSavedAd}
            />
          ) : (
            <S.MobileCarousel data-testid="mobile-carousel">
              <S.StyledList>
                {listings.length ? (
                  listings.map((result: any) => {
                    const {
                      listing: {
                        price,
                        id,
                        title,
                        numBedrooms,
                        media,
                        numBathrooms,
                        propertyType,
                        seoFriendlyPath,
                      },
                    } = result;
                    return (
                      <S.StyledListItem
                        data-testid={`mobile-carousel-card-${id}`}
                        id={`${id}`}
                        key={id}
                        noResults={false}
                        onClick={() => addSessionStorage(id)}
                      >
                        <SubUnit
                          cardType="SMALL"
                          fallback={safeGet(
                            media,
                            ['images', 0, 'size72x52'],
                            `${CDN_URL_STATIC_DIRECTORY}/static/images/fallbacks/no-image-size72x52.jpg`,
                          )}
                          lineTwoInfo={[title]}
                          lineThreeInfo={[
                            numBedrooms,
                            numBathrooms,
                            propertyType,
                          ]}
                          loadEagerly={true}
                          media={media}
                          routes={routes}
                          seoFriendlyPath={seoFriendlyPath}
                          shouldShowImage={true}
                          showTriangle={false}
                          title={price}
                        />
                      </S.StyledListItem>
                    );
                  })
                ) : (
                  <S.StyledListItem
                    data-testid="mobile-mapsearch-zero-results"
                    noResults={true}
                  >
                    <ZeroResults
                      buttonType="SECONDARY"
                      filterAPI={filterAPI}
                      hideImage={true}
                      resetFilter={resetFilter}
                      smallCard={true}
                      tryAgainMessage="Try removing some filters or zoom out"
                    />
                  </S.StyledListItem>
                )}
              </S.StyledList>
            </S.MobileCarousel>
          )}
          <S.MapSearchContainer data-testid="mapsearch-container">
            <MapSearch
              addHashToUrl={addHashToUrl}
              addSessionStorage={addSessionStorage}
              filterAPI={filterAPI.concat(hiddenFilterAPI).concat(queryFilters)}
              isMobile={isMobile}
              onboardingModal={onboardingModal}
              onMove={debouncedOnChange}
              results={listings}
              routes={routes}
              savedAds={savedAds}
              selectedListing={selectedListing}
              setOnboardingModal={closeOnboardingModal}
              setSelectedListing={setSelectedListing}
              toggleMapView={toggleMapView}
              totalResults={paging.totalResults}
              urlQuery={Router?.router?.query}
            />
          </S.MapSearchContainer>
        </S.MapWrapper>
      </MapSearchModal>
    </ErrorBoundary>
  );
};

export default SearchPageMapView;
