import React, { ComponentType, useContext, useEffect, useState } from 'react';

import { DFPManager, DFPSlotsProvider } from '@dsch/react-dfp';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { NextSeo } from 'next-seo';
import routes from '../../server/routes';

import { UserDetailsContext } from '../../contexts/userDetailsContext';
import { getGatewayEndpoint } from '../../helpers/api/gatewayUtils';
import ErrorBoundary from '../../helpers/components/ErrorBoundary';
import SmartLocalStorage from '../../helpers/storage/SmartLocalStorage';
import SmartSessionStorage from '../../helpers/storage/SmartSessionStorage';
import {
  BASE_URL,
  CDN_URL_STATIC_DIRECTORY,
  DEV_ENV,
  DFP_NETWORK_ID,
  PREVIEW_ENV,
  STAGING_ENV,
  debounce,
} from '../../helpers/utils';
import { useOnUpdateOnly } from '../../hooks/useOnUpdateOnly';
import { useWindowSize } from '../../hooks/useWindowSize';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import ErrorPage from '../../pages/_error';
import AnalyticsService from '../../services/Analytics/AnalyticsService';
import {
  FilterRequestAPI,
  FilterSearchQuery,
  SearchFilters,
  SearchRequest,
} from '../../services/Filters/FilterTypes';
import { UrlSeoService } from '../../services/SEOParamService';
import UrlService from '../../services/Url/UrlService';
import {
  AreaTag,
  Breadcrumbs,
  OffersType,
  Paging,
  PrivateUser,
  Listing,
  SECTIONS,
  SearchPropertyCard,
} from '../../types';

import { getPropertyListingSchemaLD } from './helpers/productListingSchema';
import { useSaveSearch } from './hooks/SaveSearch.hook';
import * as S from './SearchPage.styled';
import getSeoData from './seoDescriptorsService';
import { CenteredSpinner } from './styles';
import {
  getTargetingArguments,
  setDFPProps,
} from '../Toolkit/DFPContainer/DFP';
import { PopTart } from '../Toolkit/PopTart/PopTart';
import SearchPageListView from './SearchPageListView';
import SearchPageMapView from './SearchPageMapView';
import { prepareCardData } from './services/srpCardService';
import { createCard } from '../Toolkit/Card/CardFactory';
import { SearchPageFilters } from './SearchPageFilters';

const { Router } = routes;

const SettingsModal: ComponentType<any> = dynamic(
  () =>
    import(
      /*webpackChunkName: "SettingsModal-CHUNK"*/ '../Toolkit/Modals/SettingsModal'
    ).then((module) => module.SettingsModal),
  {
    ssr: false,
  },
);

type SearchProps = {
  paging: Paging;
  listings: SearchPropertyCard[];
  searchFiltersHideByDefault: SearchFilters;
  searchFiltersShowByDefault: SearchFilters;
  queryFilters?: FilterSearchQuery[];
  query: any;
  searchQuery: SearchRequest;
  status: number;
  urlPath: string;
  h1AdType: string;
  section: SECTIONS;
  showcaseListings?: [SearchPropertyCard];
  dfpTargetingValues: { [key: string]: string };
  SearchPagePagination: ComponentType<any>;
  breadcrumbs: Breadcrumbs;
  savedAds: {
    [index: number]: boolean;
  };
  toggleSavedAd: ({
    adId,
    event,
    isSaved,
    userId,
  }: {
    adId: number;
    event: React.MouseEvent;
    isSaved?: boolean;
    userId: string;
  }) => void;
  toggleSavedSearch: (method: string) => Promise<number | void>;
  toggleSavedSearchAlerts: ({ alertType, searchId }) => Promise<void>;
  deactiveSavedSearchButton: () => void;
  canonicalUrl: string;
  isActiveSavedSearchButton: boolean;
  daftCookies: { [key: string]: string };
  reactDFPAdsReadyToLoad: boolean;
  setScrollToTop: () => void;
  scrollToTop: boolean;
  path?: string;
  locationList: AreaTag[];
  user?: PrivateUser;
  savedSearch?: boolean;
  savedSearchID?: number;
};

export const getLabelText = (
  label: string | undefined,
  offers: OffersType | undefined,
) => {
  if (label === 'SALE_AGREED') return label;
  if (offers?.status === 'CLOSED') return 'Offer accepted';
  if (offers) {
    const hasOffers = offers && offers?.offersCount > 0;

    return `${hasOffers ? `${offers.offersCount} ` : ''}Online offer${
      offers?.offersCount === 1 ? '' : 's'
    }`;
  }

  return label ? label : '';
};

export const renderCard = (
  srpCardsProps,
  cardType,
  toggleSavedAds,
  savedAds,
) => {
  const {
    id,
    cardContentProps: { savedAd },
  } = srpCardsProps;

  //if ad is saved by user and page didn't fetch BE
  const isSaved = typeof savedAds[id] !== 'undefined' ? savedAds[id] : savedAd;

  const allSrpCardsProps = {
    ...srpCardsProps,
    isSaved: isSaved,
    onSaveButtonClick: toggleSavedAds,
  };

  const preparedCardData = prepareCardData(allSrpCardsProps);

  const SRPCard = createCard(cardType, preparedCardData);

  return SRPCard;
};

const SearchPage = (props: SearchProps) => {
  const { listings, showcaseListings } = { ...props };
  const router = useRouter();
  const screenWidth = useWindowSize();

  const { deactiveSavedSearchButton, path, section } = props;
  const analyticsService: AnalyticsService = new AnalyticsService(
    getGatewayEndpoint({ endpointKey: 'DAFT_API_GATEWAY' }),
  );

  const isOverseasSection = (section: SECTIONS) => {
    return (
      [SECTIONS.INTERNATIONAL_RENT, SECTIONS.INTERNATIONAL_BUY].indexOf(
        section,
      ) > -1
    );
  };
  const SearchSEOParams: UrlSeoService = new UrlSeoService([
    {
      queryParam: 'location',
      defaultString: isOverseasSection(section) ? 'worldwide' : 'ireland',
    },
    'propertyType',
  ]);

  const urlService: UrlService = new UrlService(SearchSEOParams);

  const filters = urlService.reverseEngineerValuesFromUrlForFilters(
    props.searchFiltersShowByDefault as FilterRequestAPI[],
    props.query,
  );
  const hiddenFilters = urlService.reverseEngineerValuesFromUrlForFilters(
    props.searchFiltersHideByDefault as FilterRequestAPI[],
    props.query,
  );

  const isExpiredAdRedirect = (redirectReason?: string): boolean => {
    return redirectReason != null && redirectReason === 'expired';
  };

  const hasRedirectedFromExpiredAd = isExpiredAdRedirect(
    props.query.redirectReason,
  );

  const userDetails = useContext(UserDetailsContext);

  const [selectedListing, setSelectedListing] = useState<null | Listing>(null);
  const [filterAPI, setFilterAPI] = useState<FilterRequestAPI[]>(filters);
  const [hiddenFilterAPI, setHiddenFilterAPI] =
    useState<FilterRequestAPI[]>(hiddenFilters);
  const [queryFilters, setQueryFilters] = useState<FilterSearchQuery[]>(
    props.queryFilters ? props.queryFilters : [],
  );
  const [sectionState, setSectionState] = useState<SECTIONS>(section);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSection, setIsLoadingSection] = useState<boolean>(false);
  const [showExpiredAdRedirectMessage, setShowExpiredAdRedirectMessage] =
    useState<boolean>(hasRedirectedFromExpiredAd);
  const [isMapView, setIsMapView] = useState<boolean | undefined>(
    props.query.showMap && props.query.showMap === 'true' ? true : undefined,
  );
  const [query, setQuery] = useState<any>(props.query);
  const [paging, setPaging] = useState<any>(props.paging);
  const [dfpTargetingValues, setDfpTargetingValues] = useState<any>(
    props.dfpTargetingValues,
  );

  const {
    saveSearchModalOpen,
    checkboxAttributes,
    openSaveSearchModal,
    closeSaveSearchModal,
    handleConfirmButton,
  } = useSaveSearch({
    isSavedSearch: props.isActiveSavedSearchButton,
    saveSearch: props.toggleSavedSearch,
    searchId: props.savedSearchID,
    toggleSavedSearchAlerts: props.toggleSavedSearchAlerts,
    userId: props.user?.user_id ?? userDetails?.userId,
  });

  const isMobile = screenWidth < 900;

  const startLoading = (urlProp: string) => {
    SmartSessionStorage.set(
      'SRPposition',
      window.scrollY || window.pageYOffset,
    );

    // regex safely strips leading slash
    const currentBasePath = Router.asPath.replace(/^\/+/, '').split('/')[0];
    const nextBasePath = urlProp.replace(/^\/+/, '').split('/')[0];

    if (currentBasePath !== nextBasePath) {
      setIsLoadingSection(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (props.query.showMap && props.query.showMap === 'true') {
      const scrollYPosition = SmartSessionStorage.get('SRPUrl');
      if (scrollYPosition) {
        forceUpdateHashInUrl();
        SmartSessionStorage.remove('SRPUrl');
      } else {
        addHashToUrl(listings[0]?.listing?.id);
      }
    }

    const lastSearch = SmartLocalStorage.get('source');

    const shouldShowSaveSearchModal =
      (props.user?.user_id ?? userDetails.isUserLoggedIn) &&
      lastSearch &&
      !props.savedSearch;

    if (shouldShowSaveSearchModal) {
      openSaveSearchModal('create');
    }

    SmartLocalStorage.remove('source');
  }, [props.query]);

  useEffect(() => {
    try {
      // send wootric properties to dataLayer
      analyticsService.pushToDataLayer(userDetails);
    } catch (error) {
      console.warn(error);
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      typeof isMapView !== 'undefined' &&
      props.query.showMap !== `${isMapView}`
    ) {
      if (!isMapView && typeof props.query.showMap === 'undefined') {
        return;
      }
      updateUrl({ showMap: isMapView });
    }
  }, [isMapView]);

  useEffect(() => {
    setIsMapView(props.query.showMap && props.query.showMap === 'true');
  }, [props.query.showMap]);

  useEffect(() => {
    deactiveSavedSearchButton();
    setSectionState(section);
    if (section !== sectionState) {
      setIsMapView(false);
    }
  }, [section]);

  useOnUpdateOnly(() => {
    if (!isMapView) {
      DFPManager.setTargetingArguments(
        getTargetingArguments(props.dfpTargetingValues),
      );
      DFPManager.reload(
        'df_sp_search_top',
        'df_sp_search_side',
        'df_sp_search_side_lower',
      );
    }
  }, [props.query.from, props.query.locationPath, isMapView]);

  useOnUpdateOnly(() => {
    DFPManager.getGoogletag().then(function (gt: any) {
      if (typeof gt.pubads == 'function') {
        gt.pubads().updateCorrelator();
      }
    });
  }, [props.savedAds]);

  useEffect(() => {
    setPaging(props.paging);
  }, [props.paging]);

  useEffect(() => {
    setDfpTargetingValues(props.dfpTargetingValues);
    analyticsService.pushToDataLayer(dfpTargetingValues);
  }, [props.dfpTargetingValues]);

  useEffect(() => {
    const filters = urlService.reverseEngineerValuesFromUrlForFilters(
      props.searchFiltersShowByDefault as FilterRequestAPI[],
      props.query,
    );
    const hiddenFilters = urlService.reverseEngineerValuesFromUrlForFilters(
      props.searchFiltersHideByDefault as FilterRequestAPI[],
      props.query,
    );

    const hasRedirectedFromExpiredAd = isExpiredAdRedirect(
      props.query.redirectReason,
    );

    setFilterAPI(filters);
    setHiddenFilterAPI(hiddenFilters);
    setShowExpiredAdRedirectMessage(hasRedirectedFromExpiredAd);
  }, [
    props.query,
    props.searchFiltersHideByDefault,
    props.searchFiltersShowByDefault,
  ]);

  useEffect(() => {
    if (isExpiredAdRedirect(props.query.redirectReason)) {
      setShowExpiredAdRedirectMessage(false);
      // remove redirectReason query parameter from url
      const page = window.location.pathname;
      Router.replaceRoute(page);
    }

    if ('scrollRestoration' in history) {
      // prevent weird auto scroll behaviour and handle ourselves
      history.scrollRestoration = 'manual';
    }

    const scrollYPosition = SmartSessionStorage.get('SRPposition');
    if (scrollYPosition) {
      window.scrollTo(0, scrollYPosition);
      SmartSessionStorage.remove('SRPposition');
    }
  }, []);

  useEffect(() => {
    const onRouteChange = () => {
      if (props.scrollToTop) {
        window.scrollTo(0, 0);
      }

      setIsLoadingSection(false);
      setIsLoading(false);
    };
    analyticsService.pushToDataLayer(dfpTargetingValues);
    router.events.on('routeChangeStart', startLoading);
    /***/
    // Note: This line was added to deal with the cases where the route change is not completed due to an error
    // The line below fixes a related error that is happening much more frequently due to new errors happening when clicking ads
    // https://distilledsch.tpondemand.com/entity/102568-bug-pagination-disabled-when-navigating-back
    // We may in the future want to match this function to the route change event with a specific error. For now we added this line
    // to patch the new bug above
    router.events.on('routeChangeError', () => setIsLoadingSection(false));
    /***/
    router.events.on('routeChangeComplete', onRouteChange);

    return () => {
      router.events.off('routeChangeStart', startLoading);
      /***/
      // Note: This line was added to deal with the cases where the route change is not completed due to an error
      // The line below fixes a related error that is happening much more frequently due to new errors happening when clicking ads
      // https://distilledsch.tpondemand.com/entity/102568-bug-pagination-disabled-when-navigating-back
      // We may in the future want to match this function to the route change event with a specific error. For now we added this line
      // to patch the new bug above
      router.events.on('routeChangeError', () => setIsLoadingSection(false));
      /***/
      router.events.off('routeChangeComplete', onRouteChange);
    };
  }, []);

  const onChange = async (searchFilterObject: FilterRequestAPI) => {
    deactiveSavedSearchButton();
    updateUrl(searchFilterObject);
    if (searchFilterObject.searchQueryGroup === 'paging') {
      if (typeof window !== 'undefined') {
        props.setScrollToTop();
      }
    } else {
      if (typeof window !== 'undefined') {
        // scroll to top but keep the sticky quickfilters in correct position to avoid jumping on mobile
        const scrollYPos = screen.width <= 704 ? 150 : 0;
        window.scrollTo(0, scrollYPos);
      }
    }

    const newFilterAPIState = filterAPI?.map((filter: FilterRequestAPI) => {
      if (searchFilterObject.name === filter.name) {
        return { ...filter, currentValue: searchFilterObject.currentValue };
      }
      return filter;
    });

    const newHiddenFilterAPIState = hiddenFilterAPI?.map(
      (filter: FilterRequestAPI) => {
        if (searchFilterObject.name === filter.name) {
          return { ...filter, currentValue: searchFilterObject.currentValue };
        }
        return filter;
      },
    );
    setFilterAPI(newFilterAPIState);
    setHiddenFilterAPI(newHiddenFilterAPIState);

    analyticsService.pushToDataLayer({
      event: 'FILTER_SEARCH',
      name: searchFilterObject.name,
      values: searchFilterObject.currentValue,
    });
  };

  const debouncedOnChange = debounce(onChange, 200);

  const updateUrl = (
    searchFilterObject:
      | FilterRequestAPI
      | { [key: string]: boolean | string | undefined },
  ) => {
    const currentQuery = props.query;
    const newRouteName: string | undefined = undefined;

    const routeName = newRouteName
      ? newRouteName
      : location.pathname.split('/')[1].toLowerCase();

    const queryParams = urlService.buildUrlQueryParams(
      searchFilterObject,
      currentQuery,
    );

    setQuery(queryParams);

    Router.pushRoute(routeName, queryParams);
  };

  const forceUpdateHashInUrl = () => {
    const urlNoHash = router.asPath.split('#');
    if (urlNoHash[1]) {
      router.push(router.asPath, undefined, { shallow: true });
    }
  };

  const addHashToUrl = (id: number | string) => {
    const urlNoHash = router.asPath.split('#');
    router.push(urlNoHash[0] + '#' + id, undefined, { shallow: true });
  };

  const resetAllFilters = async () => {
    const routeName = location.pathname.split('/')[1].toLowerCase();

    SearchSEOParams.resetAllParams();

    setFilterAPI(
      filterAPI?.map((filter: FilterRequestAPI) => {
        const { currentValue, ...resetFilter } = filter;
        return resetFilter;
      }),
    );
    setHiddenFilterAPI(
      hiddenFilterAPI?.map((filter: FilterRequestAPI) => {
        const { currentValue, ...resetFilter } = filter;
        return resetFilter;
      }),
    );
    setQueryFilters(
      queryFilters?.map((filter: FilterSearchQuery) => {
        const { currentValue, ...resetFilter } = filter;
        return resetFilter;
      }),
    );

    const showMap = props.query.showMap ? { showMap: props.query.showMap } : {};

    const urlQuery = props.query.sort
      ? { sort: props.query.sort, ...showMap }
      : showMap;

    Router.pushRoute(
      routeName,
      SearchSEOParams.makeReconciledQueryObject(urlQuery),
    );

    analyticsService.pushToDataLayer({
      event: 'RESET_ALL_FILTERS',
    });
  };

  const resetFilter = async (filterToClear: any) => {
    const routeName = location.pathname.split('/')[1].toLowerCase();

    SearchSEOParams.updateCurrentValue(filterToClear.name, []);

    const newFilterApi = filterAPI?.map((filter: FilterRequestAPI) => {
      if (filter.name === filterToClear.name) {
        const { currentValue, ...resetFilter } = filter;
        return resetFilter;
      }
      return filter;
    });

    const newHiddenFilterApi = hiddenFilterAPI?.map(
      (filter: FilterRequestAPI) => {
        if (filter.name === filterToClear.name) {
          const { currentValue, ...resetFilter } = filter;
          return resetFilter;
        }
        return filter;
      },
    );

    const newQueryFilters = queryFilters?.map((filter: FilterSearchQuery) => {
      if (
        filter.currentValue &&
        filter.currentValue.values &&
        filter.currentValue.values === filterToClear.currentValue.values
      ) {
        const { currentValue, values, ...resetFilter } = filter;
        return resetFilter;
      }
      return filter;
    });

    setFilterAPI(newFilterApi);
    setHiddenFilterAPI(newHiddenFilterApi);
    setQueryFilters(newQueryFilters as FilterSearchQuery[]);

    const queryFiltersObj = (newQueryFilters as FilterSearchQuery[]).reduce(
      (
        acc: {
          [key: string]: { [key: string]: FilterSearchQuery };
        },
        curr,
      ) => {
        if (!curr.currentValue || !curr.currentValue.values) return acc;
        acc[curr.name] = { [curr.currentValue.values]: curr };
        return acc;
      },
      {},
    );

    let urlQuery = query;
    for (const key in urlQuery) {
      if (urlQuery.hasOwnProperty(key)) {
        if (key.indexOf(filterToClear.name) > -1) {
          if (!queryFiltersObj[filterToClear.name]) {
            delete urlQuery[key];
          } else {
            urlQuery = {
              ...urlQuery,
              [key]: urlQuery[filterToClear.name].filter(
                (item: any) => queryFiltersObj[filterToClear.name][item],
              ),
            };
          }
        }
      }
    }

    Router.pushRoute(routeName, urlQuery);

    analyticsService.pushToDataLayer({
      event: 'RESET_FILTER',
      name: filterToClear.name,
    });
  };

  const shouldItIndex = (query: any = {}) => {
    const keys = Object.keys(query);
    const hasOneLocation =
      keys.indexOf('locationPath') > -1 && keys.indexOf('location') === -1;
    const hasPaginationParam = keys.indexOf('from') > -1;
    const hasSinglePropertyParam =
      keys.indexOf('propertyTypePath') > -1 &&
      query['propertyTypePath'] !== 'undefined';
    const isRedirect = keys.indexOf('redirectReason') > -1;
    const isAgent = keys.indexOf('agentIds') > -1;

    const pathContainsUndefined = path && path.indexOf('undefined') > -1;

    // If the path contains 'undefined'
    if (pathContainsUndefined) {
      return false;
    }

    // If we only have a location in the url (daft.ie/property-for-sale/wexford-town)
    if (keys.length === 1 && hasOneLocation) {
      return true;
    }

    // If we have a location like above and are also paginating
    if (
      (keys.length === 2 && hasOneLocation && hasPaginationParam) ||
      (keys.length === 2 && hasOneLocation && hasSinglePropertyParam)
    ) {
      return true;
    }

    // If we have a location like above and are also paginating in addition to having a property type selected
    if (
      keys.length === 3 &&
      hasOneLocation &&
      hasPaginationParam &&
      hasSinglePropertyParam
    ) {
      return true;
    }

    // If the query indicates it is a redirect or is an agent page
    if (isRedirect || isAgent) {
      return true;
    }

    return false;
  };

  const isCollegeSection = (section: string) => {
    return (
      [
        'student-accommodation-for-rent',
        'student-accommodation-to-share',
      ].indexOf(section) > -1
    );
  };

  const toggleMapView = (event?: React.MouseEvent) => {
    window.scrollTo(0, 0);
    setIsMapView(!isMapView);
    event?.preventDefault();
  };

  const shouldItBeIndexed = shouldItIndex(props.query);

  const IS_COMMERCIAL = section && section.indexOf('commercial') > -1;

  if (props.status !== 200) {
    return <ErrorPage statusCode={props.status} />;
  }

  const formattedTotalResults = paging?.totalResults?.toLocaleString();
  const {
    metaDescription,
    metaTitle,
    h1: seoH1,
  } = getSeoData(
    section,
    [...filterAPI, ...hiddenFilterAPI],
    formattedTotalResults,
    props.query.mapView,
  );

  const headerTheme = IS_COMMERCIAL ? 'COMMERCIAL' : 'RESIDENTIAL';
  const queryParamsToPersist = Object.keys(props.query).reduce((acc, key) => {
    if (key === 'location' || key === 'locationPath') {
      return { ...acc, [key]: props.query[key] };
    }
    return acc;
  }, {});

  const addSessionStorage = (id: number) => {
    const PATH = router.asPath.split('#')[0];
    const stringifyedPath = JSON.stringify([`${PATH}#${id}`]);
    SmartSessionStorage.set('SRPUrl', stringifyedPath);
  };

  const isOverseasProperty =
    section === 'international-for-sale' || section === 'international-to-rent';

  const agentIdsFilter =
    listings?.length > 0 &&
    queryFilters?.find((filter) => filter.name === 'agentIds');
  const isRecentSection = section === SECTIONS.RECENT;

  return (
    <>
      <ErrorBoundary
        renderFallback={() => <ErrorPage statusCode={props.status} />}
      >
        <Script id="propertyListings" type="application/ld+json">
          {getPropertyListingSchemaLD(listings, metaTitle)}
        </Script>
        <NextSeo
          title={metaTitle}
          noindex={!shouldItBeIndexed}
          canonical={props.canonicalUrl}
          description={metaDescription}
          openGraph={{ title: metaTitle, description: metaDescription }}
        />
        <DFPSlotsProvider
          disableInitialLoad
          {...setDFPProps(
            {
              targetingArguments: props.dfpTargetingValues,
              dfpNetworkId: DFP_NETWORK_ID,
              collapseEmptyDivs: true,
            },
            DEV_ENV || PREVIEW_ENV || STAGING_ENV,
          )}
        >
          <DefaultLayout
            headerTheme={headerTheme}
            category={sectionState}
            pageVariant={IS_COMMERCIAL ? 'COMMERCIAL' : undefined}
            breadcrumbs={props.breadcrumbs}
            BASE_URL={BASE_URL}
            CDN_URL_STATIC_DIRECTORY={CDN_URL_STATIC_DIRECTORY}
            backgroundColor="#f4f4f4"
            routes={routes}
            isMapView={isMapView || false}
          >
            {!isMapView && (
              <CenteredSpinner
                isLoading={isLoading}
                data-testid="loading-spinner"
              />
            )}
            <ErrorBoundary
              renderFallback={() => (
                <S.ErrorMessageContainer>
                  Sorry, something went wrong with the Search Page Filters
                </S.ErrorMessageContainer>
              )}
            >
              <SearchPageFilters
                filterAPI={filterAPI}
                headerTheme={headerTheme}
                isActiveSavedSearchButton={props.isActiveSavedSearchButton}
                isLoadingSection={isLoadingSection}
                isMapView={isMapView}
                isRecentSection={isRecentSection}
                hiddenFilterAPI={hiddenFilterAPI}
                locationSearchPlaceholderText={
                  isCollegeSection(section)
                    ? 'College or University'
                    : 'County, City, Town or Area'
                }
                onChange={debouncedOnChange}
                onFiltersChange={debouncedOnChange}
                onSaveSearchButtonClick={openSaveSearchModal}
                query={props.query}
                queryParamsToPersist={queryParamsToPersist}
                resetAllFilters={resetAllFilters}
                resetCallback={resetAllFilters}
                searchQuery={props.searchQuery}
                section={section}
                showHeaders
                totalResults={paging.totalResults}
              />
            </ErrorBoundary>
            {showExpiredAdRedirectMessage &&
              PopTart.show({
                type: 'ERROR',
                message:
                  'This ad is no longer available. You can find similar ads below.',
              })}
            {saveSearchModalOpen && (
              <SettingsModal
                ariaLabelText="Save this search to use it again"
                headerText="Save this search to use it again"
                cancelButtonAttributes={{
                  cancelText: 'Cancel',
                  onClick: closeSaveSearchModal,
                }}
                confirmButtonAttributes={{
                  confirmText: 'Save search',
                  onClick: handleConfirmButton,
                }}
                modalIsOpen={saveSearchModalOpen}
                checkboxAttrs={checkboxAttributes}
                closeModal={closeSaveSearchModal}
                hasCloseButton={false}
              />
            )}

            {isMapView && !isOverseasProperty && !isRecentSection && (
              <SearchPageMapView
                addHashToUrl={addHashToUrl}
                addSessionStorage={addSessionStorage}
                agentIdsFilter={agentIdsFilter}
                debouncedOnChange={debouncedOnChange}
                filterAPI={filterAPI}
                hiddenFilterAPI={hiddenFilterAPI}
                isCommercial={IS_COMMERCIAL}
                isLoading={isLoading}
                isMapView={isMapView}
                isMobile={isMobile}
                listings={listings}
                paging={paging}
                queryFilters={queryFilters}
                resetFilter={resetFilter}
                savedAds={props.savedAds}
                selectedListing={selectedListing}
                seoH1={seoH1}
                setSelectedListing={setSelectedListing}
                showcaseListings={showcaseListings}
                toggleMapView={toggleMapView}
                toggleSavedAd={props.toggleSavedAd}
              />
            )}
            {!isMapView && (
              <SearchPageListView
                addSessionStorage={addSessionStorage}
                agentIdsFilter={agentIdsFilter}
                debouncedOnChange={debouncedOnChange}
                filterAPI={filterAPI}
                hiddenFilterAPI={hiddenFilterAPI}
                isLoading={isLoading}
                isLoadingSection={isLoadingSection}
                isMobile={isMobile}
                isOverseasProperty={isOverseasProperty}
                listings={listings}
                onChange={debouncedOnChange}
                paging={paging}
                query={props.query}
                queryFilters={queryFilters}
                reactDFPAdsReadyToLoad={props.reactDFPAdsReadyToLoad}
                resetFilter={resetFilter}
                savedAds={props.savedAds}
                SearchPagePagination={props.SearchPagePagination}
                section={section}
                seoH1={seoH1}
                setSelectedListing={setSelectedListing}
                showcaseListings={showcaseListings}
                toggleMapView={toggleMapView}
                toggleSavedAd={props.toggleSavedAd}
                isMapView={isMapView}
                isRecentSection={isRecentSection}
              />
            )}
          </DefaultLayout>
        </DFPSlotsProvider>
      </ErrorBoundary>
    </>
  );
};

export default SearchPage;
