import React, { useContext, useEffect, useState } from 'react';

import { DFPManager } from '@dsch/react-dfp';
import { WithRouterProps } from 'next/dist/client/with-router';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';

import { AccountsAPI, SearchAPI } from '../api';
import { useLastSearch } from '../components/HomePage/homepage-components/LastSearch/LastSearch.hook';
import SearchPage from '../components/SearchPage/SearchPage';
import { PopTart } from '../components/Toolkit/PopTart/PopTart';
import { authorise } from '../helpers/auth/auth';
import { generateRecaptchaToken } from '../helpers/reCaptcha/reCaptcha';
import SmartLocalStorage from '../helpers/storage/SmartLocalStorage';
import SmartSessionStorage from '../helpers/storage/SmartSessionStorage';
import buildRouteBaseQuery from '../helpers/url/UrlRouteQueryDecorator';
import { getBasePath, getPath } from '../helpers/url/urlUtils';
import { IS_PROD, safeGet, upperCaseFirstLetter } from '../helpers/utils';
import { useOnUpdateOnly } from '../hooks/useOnUpdateOnly';
import ErrorPage from '../pages/_error';
import { GoogleAnalyticsService } from '../services/Analytics/AnalyticsService';
import {
  FilterRequestAPI,
  FilterSearchQuery,
  SearchFilters,
  SearchRequest,
} from '../services/Filters/FilterTypes';
import { toggleSavedAd } from '../services/SavedAds/toggleSavedAd';
import {
  createSavedSearch,
  removeSavedSearch,
} from '../services/SavedSearches/savedSearches';
import {
  buildInitialSearchQueryFromQueryParamsAndFilters,
  getAgentIdQueryFilterFromUrlQueryParams,
  getSectionFromQueryParams,
} from '../services/Search/SearchUtils';
import {
  AreaTag,
  NextContext,
  Paging,
  SECTIONS,
  SearchPropertyCard,
} from '../types';
import { UserDetailsContext } from '../contexts/userDetailsContext';

type SearchProps = {
  breadcrumbs: any;
  canonicalUrl: string;
  daftCookies: { [key: string]: string };
  dfpTargetingValues: { [key: string]: string };
  h1AdType: string;
  isLastSearchRedirect?: boolean;
  isProd?: boolean;
  listings: [SearchPropertyCard];
  locationList: AreaTag[];
  paging: Paging;
  query: any;
  queryFilters: FilterSearchQuery[];
  reactDFPAdsReadyToLoad: boolean;
  savedSearch: boolean;
  savedSearchID?: number;
  searchFiltersHideByDefault: SearchFilters;
  searchFiltersShowByDefault: SearchFilters;
  searchQuery: SearchRequest;
  section: SECTIONS;
  showcaseListings?: [SearchPropertyCard];
  status: number;
  toggleSavedAd: (
    event: React.MouseEvent,
    id: number,
    isSaved: boolean,
  ) => void;
  urlPath: string;
} & WithRouterProps;

const googleAnalyticsService: GoogleAnalyticsService =
  new GoogleAnalyticsService();

export const Search = withRouter((props: SearchProps) => {
  const SearchPagePagination = dynamic(
    import('../components/SearchPage/SearchPagePagination'),
    {
      loading: () => (
        <>
          <a
            href={`${safeGet(
              props,
              ['breadcrumbs', 1, 'url'],
              '',
            )}?from=${safeGet(props, ['paging', 'previousFrom'])}`}
            rel="prev"
          >
            Previous page
          </a>
          <a
            href={`${safeGet(
              props,
              ['breadcrumbs', 1, 'url'],
              '',
            )}?from=${safeGet(props, ['paging', 'nextFrom'])}`}
            rel="next"
          >
            Next page
          </a>
        </>
      ),
    },
  );

  const userDetails = useContext(UserDetailsContext);
  const { status, daftCookies } = props;
  const [savedAds, setSavedAds] = useState<{
    [index: number]: boolean;
  }>({});
  const [savedSearchID, setSavedSearchID] = useState<number>(0);
  const [isActiveSavedSearchButton, setIsActiveSavedSearchButton] =
    useState<boolean>(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);

  const { createLastSearch } = useLastSearch({
    searchQuery: props.searchQuery,
    locationList: props.locationList,
    getTitleSearch,
  });

  function handleScrollToTop() {
    setScrollToTop(true);
  }

  function getTitleSearch() {
    const {
      query: { locationPath },
      searchQuery: {
        geoFilter: { storedShapeIds },
      },
      locationList,
    } = props;

    let titleSearch = '';

    if (storedShapeIds?.length > 0) {
      const areaName = locationList.filter(
        (location: AreaTag) => location.id === storedShapeIds[0],
      )[0]?.displayName;

      titleSearch =
        storedShapeIds.length === 1
          ? areaName
          : `${areaName} (+${storedShapeIds.length - 1})`;
    } else {
      titleSearch = upperCaseFirstLetter(locationPath);
    }

    return titleSearch;
  }

  const handleToggleSavedAd = async ({ adId, event, isSaved, userId }) => {
    const newSavedAd = await toggleSavedAd({
      adId,
      event,
      isSaved,
      redirectBackTo: props.urlPath,
      userId,
    });

    setSavedAds({ ...savedAds, ...newSavedAd });
  };

  const handleToggleSavedSearch = async (
    method: string,
  ): Promise<number | void> => {
    if (method === 'create') {
      return await handleCreateSavedSearch();
    } else {
      await handleRemoveSavedSearch();
    }
  };

  const handleCreateSavedSearch = async (): Promise<number> => {
    const { searchQuery, urlPath } = props;
    const titleSearch = getTitleSearch();

    let searchQueryUpdated = searchQuery.sort
      ? searchQuery
      : { ...searchQuery, sort: 'bestMatch' };

    if (!searchQuery.geoFilter?.geoSearchType) {
      searchQueryUpdated = {
        ...searchQueryUpdated,
        geoFilter: { storedShapeIds: [] },
      };
    }

    const { status, savedSearchID } = await createSavedSearch({
      redirectBackTo: urlPath,
      search: searchQueryUpdated,
      titleSearch,
    });

    const responseOk = status === 201;
    setSavedSearchID(responseOk ? savedSearchID : 0);
    setIsActiveSavedSearchButton(responseOk);

    return responseOk ? savedSearchID : 0;
  };

  async function handleRemoveSavedSearch() {
    const status = await removeSavedSearch({
      redirectBackTo: props.urlPath,
      searchId: savedSearchID,
      siteUserId: userDetails.userId,
    });
    const responseOk = status === 200;

    setSavedSearchID(responseOk ? 0 : savedSearchID);
    setIsActiveSavedSearchButton(!responseOk);
  }

  useEffect(() => {
    SmartSessionStorage.set('locations', props.locationList);
    SmartSessionStorage.set(
      `searchFiltersShowByDefault${props.section}`,
      props.searchFiltersShowByDefault,
    );
    SmartSessionStorage.set(
      `searchFiltersHideByDefault${props.section}`,
      props.searchFiltersHideByDefault,
    );
  }, [props.section]);

  useEffect(() => {
    // if SRP is a saved search, save search button is Active
    if (props.savedSearch !== isActiveSavedSearchButton) {
      setIsActiveSavedSearchButton(props.savedSearch);
    }

    if (props.savedSearchID) {
      setSavedSearchID(props.savedSearchID);
    }
  }, [props.savedSearch]);

  useEffect(() => {
    const lastSearch = createLastSearch();
    SmartLocalStorage.set('lastSearchQuery', lastSearch);
  }, [props.query]);

  const handleToggleSavedSearchAlerts = async ({
    alertType,
    searchId,
  }): Promise<void> => {
    const { urlPath } = props;

    const token = await authorise(
      {
        forceLogIn: true,
        redirectBackTo: urlPath,
      },
      'daft',
    );

    try {
      if (token) {
        const res = await AccountsAPI.toggleSavedSearchAlerts({
          alertType,
          searchId,
          siteUserId: userDetails.userId,
          token,
        });
        if (res.status !== 200) {
          PopTart.show({
            type: 'ERROR',
          });
        }
      }
    } finally {
      googleAnalyticsService.pushToDataLayer({
        event: 'SEARCH_INTERACTION',
        name: `alert-${alertType === 'NONE' ? 'off' : 'on'}`,
      });
    }
  };

  // generate recaptcha token for data tracking
  useEffect(() => {
    generateRecaptchaToken('search_page');
  }, []);

  useOnUpdateOnly(() => {
    DFPManager.getGoogletag().then(function (gt: any) {
      if (typeof gt.pubads == 'function') {
        gt.pubads().updateCorrelator();
      }
    });
  }, [savedSearchID]);

  if (status !== 200) {
    return <ErrorPage statusCode={status} />;
  }

  return (
    <SearchPage
      {...props}
      daftCookies={daftCookies}
      deactiveSavedSearchButton={() => setIsActiveSavedSearchButton(false)}
      isActiveSavedSearchButton={isActiveSavedSearchButton}
      savedAds={savedAds}
      scrollToTop={scrollToTop}
      SearchPagePagination={SearchPagePagination}
      setScrollToTop={handleScrollToTop}
      toggleSavedAd={handleToggleSavedAd}
      toggleSavedSearch={handleToggleSavedSearch}
      toggleSavedSearchAlerts={handleToggleSavedSearchAlerts}
    />
  );
});

export default Search;

(Search as any).getInitialProps = async (ctx: NextContext) => {
  const { query, res, req } = ctx;
  const originalUrl = req?.originalUrl ?? ctx.asPath; // With Next 13 upgrade ctx.asPath is not always available and doesn't return the correct path. Keeping it as a fallback for now.
  const baseRouteName = getBasePath({ originalUrl });

  if (query.propertyTypePath === 'undefined') {
    query.propertyTypePath = '';
  }

  const { h1AdType, query: baseQuery } = buildRouteBaseQuery(
    baseRouteName,
    query,
  );

  const section = getSectionFromQueryParams(baseQuery);

  let locationListSessionStorage;
  let hideByDefaultSessionStorage;
  let showByDefaultSessionStorage;
  let isLastSearchRedirect = false;

  if (typeof window !== 'undefined') {
    locationListSessionStorage = SmartSessionStorage.get('locations');
    hideByDefaultSessionStorage = SmartSessionStorage.get(
      `searchFiltersHideByDefault${section}`,
    );
    showByDefaultSessionStorage = SmartSessionStorage.get(
      `searchFiltersShowByDefault${section}`,
    );
    isLastSearchRedirect = SmartSessionStorage.get('source') ? true : false;
  }

  let [
    // eslint-disable-next-line prefer-const
    token,
    locationList,
    {
      hideByDefault: searchFiltersHideByDefault,
      showByDefault: searchFiltersShowByDefault,
    },
  ] = await Promise.all([
    authorise(
      {
        ctx,
        forceLogIn: false,
      },
      'daft',
    ),
    !locationListSessionStorage && SearchAPI.getLocationTags(),
    !showByDefaultSessionStorage
      ? SearchAPI.getFilters(section)
      : {
          hideByDefault: [],
          showByDefault: [],
        },
  ]);

  locationList = locationListSessionStorage || locationList;
  searchFiltersHideByDefault =
    hideByDefaultSessionStorage || searchFiltersHideByDefault;
  searchFiltersShowByDefault =
    showByDefaultSessionStorage || searchFiltersShowByDefault;

  const searchQuery = buildInitialSearchQueryFromQueryParamsAndFilters(
    baseQuery,
    locationList,
    searchFiltersHideByDefault.concat(
      searchFiltersShowByDefault as any,
    ) as FilterRequestAPI[],
    (searchFiltersHideByDefault as any).concat(searchFiltersShowByDefault),
    section,
  );

  const {
    listings = [],
    showcaseListings = [],
    paging = { displayingFrom: 0, displayingTo: 0 },
    status,
    dfpTargetingValues,
    breadcrumbs,
    canonicalUrl,
    savedSearch,
    savedSearchID,
  } = await SearchAPI.search(token, searchQuery);

  if (res && status && status !== 200) {
    res.status(status);
  }

  searchFiltersShowByDefault =
    searchFiltersShowByDefault &&
    searchFiltersShowByDefault.map((filter: any) =>
      filter.name === 'location' ? { ...filter, values: locationList } : filter,
    );

  searchFiltersHideByDefault =
    searchFiltersHideByDefault &&
    searchFiltersHideByDefault.map((filter: any) =>
      filter.name === 'location' ? { ...filter, values: locationList } : filter,
    );

  const queryFilters = getAgentIdQueryFilterFromUrlQueryParams(
    searchQuery,
    listings,
  );

  return {
    breadcrumbs,
    canonicalUrl,
    dfpTargetingValues,
    h1AdType,
    isLastSearchRedirect,
    isProd: IS_PROD,
    listings,
    locationList,
    paging,
    query,
    queryFilters,
    savedSearch,
    savedSearchID,
    searchFiltersHideByDefault,
    searchFiltersShowByDefault,
    searchQuery,
    section,
    showcaseListings,
    status,
    urlPath: getPath(originalUrl),
  };
};
