import styled from 'styled-components';

export const TopPanelAnchor = styled.a<{ border: boolean }>`
  width: 100%;
  min-height: 80px;
  border: ${({ border, theme }) =>
    border && `1px solid ${theme.color.GREY_LIGHT}`};
  border-radius: ${({ theme }) => theme.spacing.S4};
  background-color: ${({ theme }) => theme.color.WHITE};
  color: ${({ theme }) => theme.color.GREY_DARKER};
  padding: ${({ theme }) => theme.spacing.M20} 19px;
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  ${({ theme }) => theme.fontSize.S14};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.S8};
`;

export const ChevronIconWrapper = styled.div`
  #chevron-right-icon {
    width: ${({ theme }) => theme.spacing.S12};
    height: ${({ theme }) => theme.spacing.S12};
  }
`;

export const TopPanelHeading = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  margin-bottom: ${({ theme }) => theme.spacing.S2};
`;
