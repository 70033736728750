/**
 * A simple implementation of localStorage which uses JSON APIs
 * to encode and decode JSON objects.
 *
 * note: could unify this with SmartSessionStorage as the interface is identical,
 * could just initialise a "SmartStorage" class with either local or session storage
 * */

const SmartLocalStorage = {
  set(name: string, value: any) {
    if (!window) throw Error('Method can only be used on client');
    window.localStorage.setItem(name, JSON.stringify(value));
  },
  get(name: string) {
    if (!window) throw Error('Method can only be used on client');
    const value = window.localStorage.getItem(name);
    try {
      return value ? JSON.parse(value) : null;
    } catch (e) {
      console.error(
        `JSON parse getting localStorage item: "${name}" caused an error`,
        e,
      );
      return null;
    }
  },
  remove(name: string) {
    if (!window) throw Error('Method can only be used on client');
    window.localStorage.removeItem(name);
  },
  clear() {
    if (!window) throw Error('Method can only be used on client');
    window.localStorage.clear();
  },
};

export default SmartLocalStorage;
