import { AccountsAPI } from '../../api';
import { PopTart } from '../../components/Toolkit/PopTart/PopTart';
import { authorise } from '../../helpers/auth/auth';
import { GoogleAnalyticsService } from '../../services/Analytics/AnalyticsService';
import { SearchRequest } from '../../services/Filters/FilterTypes';

const googleAnalyticsService: GoogleAnalyticsService =
  new GoogleAnalyticsService();

export const createSavedSearch = async ({
  redirectBackTo,
  search,
  titleSearch,
}: {
  redirectBackTo: string;
  search: SearchRequest;
  titleSearch: string;
}) => {
  let status, savedSearchID;

  const token = await authorise(
    {
      forceLogIn: true,
      redirectBackTo,
    },
    'daft',
  );

  try {
    if (token) {
      const res = await AccountsAPI.createSavedSearch({
        searchRequest: search,
        title: titleSearch,
        token,
      });
      status = res.status;
      savedSearchID = res.savedSearchID;

      if (status !== 201) {
        PopTart.show({
          type: 'ERROR',
          message: status === 422 ? res.errors[0].message : '',
        });
      }
    }
  } finally {
    googleAnalyticsService.pushToDataLayer({
      event: 'SEARCH_INTERACTION',
      name: 'createSavedSearch',
    });

    return { status, savedSearchID };
  }
};

export const removeSavedSearch = async ({
  redirectBackTo,
  searchId,
  siteUserId,
}: {
  redirectBackTo: string;
  searchId: number;
  siteUserId: string;
}) => {
  let status;

  const token = await authorise(
    {
      forceLogIn: true,
      redirectBackTo,
    },
    'daft',
  );

  try {
    if (token) {
      const res = await AccountsAPI.deleteSavedSearch({
        searchId,
        siteUserId,
        token,
      });
      status = res.status;

      if (status !== 200) {
        PopTart.show({
          type: 'ERROR',
        });
      }
    }
  } finally {
    googleAnalyticsService.pushToDataLayer({
      event: 'SEARCH_INTERACTION',
      name: 'deleteSavedSearch',
    });

    return status;
  }
};
