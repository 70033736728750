const getBasePath = ({
  originalUrl,
  indexOfBasePath = 0,
}: {
  originalUrl: string;
  indexOfBasePath?: number;
}) =>
  originalUrl
    .split('/')
    [(originalUrl.charAt(0) === '/' ? 1 : 0) + indexOfBasePath].split('?')[0]
    .toLowerCase();

const getPath = (fullPath: string) => fullPath.split('?')[0];

const stripLeadingForwardSlash = (fullPath: string) =>
  fullPath.charAt(0) === '/'
    ? fullPath.substring(1, fullPath.length)
    : fullPath;

export { getBasePath, getPath, stripLeadingForwardSlash };
