import React, { forwardRef } from 'react';

import { ChevronRightIcon } from '@dsch/icons';

import { IconWithContentAndLabel } from '../../../components/Toolkit/IconWithContentAndLabel/IconWithContentAndLabel';

import {
  ChevronIconWrapper,
  TopPanelAnchor,
  TopPanelHeading,
} from './ClickablePanel.styled';

type PanelProps = {
  id: string;
  IconComponent: JSX.Element;
  content: {
    heading: string;
    subHeading: string;
  };
  href: string;
  dataTracking: string;
  border?: boolean;
  spaceBetween?: 'small' | 'medium' | 'large';
  opensInSameTab?: boolean;
  onClick?: () => void;
};

const ClickablePanel = forwardRef<HTMLAnchorElement, PanelProps>(
  (
    {
      IconComponent,
      content,
      href,
      dataTracking,
      id,
      border = true,
      spaceBetween = 'small',
      opensInSameTab = false,
      onClick,
    },
    ref,
  ) => {
    return (
      <TopPanelAnchor
        href={href}
        data-tracking={dataTracking}
        id={id}
        data-testid={id}
        target={opensInSameTab ? '_self' : '_blank'}
        border={border}
        onClick={onClick}
        ref={ref}
      >
        <IconWithContentAndLabel
          dataTestID="IconWithContentAndLabel"
          IconComponent={IconComponent}
          content={
            <div>
              <TopPanelHeading data-tracking={dataTracking} id={id}>
                {content.heading}
              </TopPanelHeading>
              <p data-tracking={dataTracking} id={id}>
                {content.subHeading}
              </p>
            </div>
          }
          spaceBetween={spaceBetween}
          iconVAlignment="center"
        />
        <ChevronIconWrapper>
          <ChevronRightIcon id="chevron-right-icon" />
        </ChevronIconWrapper>
      </TopPanelAnchor>
    );
  },
);

export default ClickablePanel;
