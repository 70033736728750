import React, { ReactElement } from 'react';

import styled from 'styled-components';

import { FilterRequestAPI } from '../../services/Filters/FilterTypes';
import { hasValidValue } from '../../services/Search/SearchUtils';

import { SemanticButtonTypes } from '../../types';
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
} from '@dist-property-frontend/toolkit';

type FilterButtonsProps = {
  filterButtonSize?: ButtonSizes;
  filters: FilterRequestAPI[];
  resetFilter: (filter: FilterRequestAPI) => void;
};

const ButtonContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.M24};
  margin-top: ${({ theme }) => theme.spacing.S8};

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (min-width: 704px) {
    margin-bottom: ${({ theme }) => theme.spacing.M32};
  }
`;

const ButtonWrap = styled.div`
  margin-top: ${({ theme }) => theme.spacing.S8};
  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.S8};
  }
  max-width: 250px;

  @media only screen and (min-width: 800px) {
    max-width: 550px;
  }
`;

const FilterButtons = (props: FilterButtonsProps): ReactElement => {
  const buttons = props.filters.map(
    (filter: FilterRequestAPI, index: number) => {
      let filterObj: any;
      const currentValue = filter.currentValue;
      if (typeof currentValue === 'string') {
        filterObj = {
          ...filter,
          currentValue,
        };
      } else {
        filterObj = {
          ...filter,
          ...currentValue,
        };
      }

      return hasValidValue(filterObj) ? (
        <ButtonWrap key={index}>
          <Button
            buttonSize={props.filterButtonSize || ButtonSizes.X_SMALL}
            buttonType={ButtonTypes.TERTIARY}
            data-testid={`query-filter-${filter.name}-${index}`}
            data-tracking="query-filters-agent"
            Icon={<Icon icon="CLOSE" size={16} />}
            onClick={() => props.resetFilter(filter)}
            type={SemanticButtonTypes.button}
          >
            {filter.displayName ?? filter.name}
          </Button>
        </ButtonWrap>
      ) : null;
    },
  );

  return (
    <ButtonContainer data-testid="query-filters">{buttons}</ButtonContainer>
  );
};

export default FilterButtons;
